<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <v-row class="smy_filter_section">
        <v-col>
          <intervalFilter
            ref="intervalFilter"
            v-bind:intervalsExplorerIsOpen="explorerIsOpen"
            v-on:selection-changed="selectionChanged"
            v-on:date-range-changed="dateRangeChanged"
            v-on:clickExplore="clickExplore">
          </intervalFilter>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="smy_contain">
      <v-row class="mt-0">
        <v-col class="pa-0">
          <v-expand-transition>

            <v-card v-show="explorerIsOpen === true" tile outlined  class="explorer-container pa-4">
              <v-dialog v-model="newAndRenameDialog" max-width="500px" scrollable persistent>
                <v-form v-model="newAndRenameValid" @submit.prevent>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ newAndRenameDialogRenaming ? 'Rename' : 'Save As...' }}</span>
                  </v-card-title>

                  <v-expand-transition>
                    <div v-if="newAndRenameError" class="dialog-error">
                      {{ newAndRenameError }}
                    </div>
                  </v-expand-transition>

                  <v-card-text style="height: 100%; overflow-y: auto;">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-model="newAndRenameName" label="Interval Name" :rules="[rules.required, rules.minLength]" outlined autofocus ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <div v-if="newAndRenameProcessing">
                      Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="newAndRenameClose" :disabled="newAndRenameProcessing">Cancel</v-btn>
                    <v-btn color="green accent-3" text @click="newAndRenameSave" type="submit" :disabled="!newAndRenameValid || newAndRenameProcessing">{{ newAndRenameDialogRenaming ? 'Rename' : 'Save' }}</v-btn>
                  </v-card-actions>
                </v-card>
                </v-form>
              </v-dialog>

              <v-card-text>
                <v-row v-if="isAdminOfCurrentClinic && selectedIntervalId">
                  <v-col cols=12 sm=12 md=12 lg=12>
                    <div class="d-flex">
                      <v-text-field hide-details v-model="configIntervalName" label="Interval Name" disabled outlined dense></v-text-field>
                      <v-btn @click="clickRename" class="ml-2">Rename</v-btn>
                      <v-btn @click="clickDelete" class="ml-2">Delete</v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-form v-model="witnesspointListsValid">
                <v-row>
                  <v-col cols=12 sm=12 md=12 lg=4>
                    <v-select v-model="configIntervalType" :items="configIntervalTypes" item-text="name" item-value="value" label="Interval Type"></v-select>

                    <!-- hint for type of interval goes here. i.e. "OuterTime gives the elapsed time between the start time of the first witness point of the starting group, and the end time of the last witness point of the end group" -->
                    <div v-if="configIntervalType === 'OuterTime'" class="mt-0 pt-0">
                      This interval type gives the elapsed time between the <b>start time</b> of the <b>earliest</b> witness point of the starting group, and the <b>end time</b> of the <b>latest</b> witness point of the end group.
                    </div>
                    <div v-if="configIntervalType === 'InnerTime'" class="mt-0 pt-0">
                      This interval type gives the elapsed time between the <b>end time</b> of the <b>latest</b> witness point of the starting group, and the <b>start time</b> of the <b>earliest</b> witness point of the end group.
                    </div>
                    <div v-if="configIntervalType === 'SumDuration'" class="mt-0 pt-0">
                      This interval type gives the sum of the durations of all of the witness points in the start and the end groups. (Where the 'duration' of a witness point is the elapsed time between the first tagged item entering a workarea and the last tagged item leaving a workarea for a given witness point).
                    </div>
                  </v-col>
                  <v-col cols=12 sm=12 md=12 lg=8>
                    <v-row>
                      <v-col cols=12 sm=12 md=6 lg=6>
                        <v-autocomplete
                          :items="witnesspointNames"
                          label="Start Witnesspoints"
                          v-model="configStartWitnesspointNames"
                          class="ml-2 mr-2"
                          :loading="loading"
                          :disabled="!selectedClinicId"
                          multiple
                          chips
                          deletable-chips
                          clearable
                          small-chips
                          menu-props="bottom, overflowY, offset-y"
                          :rules="[rules.atLeastOneItem]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols=12 sm=12 md=6 lg=6>
                        <v-autocomplete
                          :items="witnesspointNames"
                          label="End Witnesspoints"
                          v-model="configEndWitnesspointNames"
                          class="ml-2 mr-2"
                          :loading="loading"
                          :disabled="!selectedClinicId"
                          multiple
                          chips
                          deletable-chips
                          clearable
                          small-chips
                          menu-props="bottom, overflowY, offset-y"
                          :rules="configIntervalType !== 'SumDuration' ? [rules.atLeastOneItem] : []"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="isAdminOfCurrentClinic" @click="clickSaveAsNew" class="mr-2" :disabled="configProcessing || !witnesspointListsValid">Save as New</v-btn>
                <v-btn :disabled="configProcessing" @click="clickSave" v-if="isAdminOfCurrentClinic && selectedIntervalId">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="selectedIntervalId" @click="closeExplorer" :disabled="configProcessing">{{ isAdminOfCurrentClinic ? 'Cancel/Close' : 'Finished' }}</v-btn>
              </v-card-actions>
            </v-card>

          </v-expand-transition>
        </v-col>
      </v-row>


      <v-row style="margin-bottom: 12px;">
        <v-col>
          <v-expand-transition>
          <v-card tile class="" flat color="#F6F7F9">
            <v-card-text class="ma-0 pa-0">
              <v-container fluid class="pa-0 ma-0">
                <v-row>
                  <v-col xs=12 class="pb-0 mb-0 mt-0 pt-0">
                    <v-progress-linear
                      color="primary"
                      :active="loading"
                      :indeterminate="loading"
                      rounded
                      height="3"
                      style="margin-top: -6px;"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <div>
        <div dense>
          <v-row dense :style="explorerIsOpen === false ? 'margin-top: -25px;' : ''">
            <h2 class="ml-2">{{ titleFull }}</h2>
          </v-row>
          <v-row>
            <p class="ml-4">Interval type: <b>{{ intervalTypeDescription + '    '}}</b> Start group: <b>[ {{ intervalStartGroupDescription }} ]</b>{{ '    ' }} End group: <b>[ {{ intervalEndGroupDescription }} ]</b></p>
          </v-row>
          <v-row>
            <witnessSummary v-if="!explorerIsOpen" mode="avgDuration" :requestDetails="witnessSummaryDetails" requiresInterval/>
          </v-row>
          <v-row>
            <v-col v-if="dateFilter.jumpAmount" cols=12 md=12 lg=6 xl=4 offset=0>
              <witness-historical
              :xAxisTitlePeriod="jumpPeriodForHistoricalChart"
              :loading="historicalDataLoading"
              :metadata="chartData" modeKey="average_duration"
              :autoResize="true"
              card_title_base="interval"
              titleOverride="Historical Interval"
              @filter="filterToProvidedDateRange"
              ></witness-historical>
            </v-col>
            <v-col cols=12 md=12 lg=6 xl=6 offset=0>
              <witness-histogram
              :loading="histogramDataLoading"
              :metadata="chartData"
              modeKey="count" :autoResize="true"
              :card_title_base="(selectedIntervalConfig && selectedIntervalConfig.name) ? selectedIntervalConfig.name : ''"
              titleOverride="Interval Histogram"
              ></witness-histogram>
            </v-col>
          </v-row>
        </div>
      </div>
  </v-container>
  </v-container>
</template>

<style scoped>
* >>> .v-menu__content--auto {
  max-height: 400px !important;
}
* >>> .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}
pre {
  white-space: pre-wrap;
}

.explorer-container {
  background-color: #FCFCFC;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 3px solid #F2F2F2;
}

.dialog-error {
  text-align: center;
}

</style>

<script>

import witnessIntervalsViewModel from '@/view-models/witness-intervals-view-model';

export default witnessIntervalsViewModel;

</script>
