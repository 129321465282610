import apiEndpoints from '@/models/common/api-endpoints';
import dateTimeFormatter from '@/models/common/date-time-formatter';
import dayjs from 'dayjs';
import generalFunctions from '@/models/common/general-functions';
import intervalFilter from '@/components/IntervalFilter';
import { mapGetters } from 'vuex';
import witnessDatePicker from '@/components/WitnessDatePicker';
import witnessHistogram from '@/components/WitnessHistogram';
import witnessHistorical from '@/components/WitnessHistorical';
import witnessSummary from '@/components/WitnessSummary';

// import LogRocket from 'logrocket'

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: 'witness_intervals',
  components: {
    intervalFilter,
    witnessHistogram,
    witnessHistorical,
    witnessSummary,
    witnessDatePicker
  },
  data () {
    return {
      // Page
      explorerIsOpen: false,
      errorMessage: null,
      showError: false,
      
      // WitnessPoint Names
      witnesspointNamesLoading: false,
      witnesspointNames: [],
      
      // Selected Clinic and Interval
      selectedClinicId: null,
      selectedDeviceId: null,
      selectedIntervalId: null,
      selectedIntervalConfig: null,
      selectedStartDate: null,

      // Calculation start point and grouping for Historical data
      historicalStartDate: null,
      historicalGroupBy: null,
      
      // Status of Interval Data
      witnessIntervalDataLoading: false,

      // Status of histogram and historical Data
      histogramDataLoading: false,
      historicalDataLoading: false,

      // Chart Output - matches initHistogramOutput and initHistoricalOutput
      chartData: {
        histogram: {
          binSize: 1,
          binNum: [],
          binVal: [],
          count: [],
          average_duration: [],
          avg_average_duration: 0
        },
        historical: {
          dates: [],
          count_with_duration: [],
          average_duration: []
        }
      },
      
      // Explorer
      configProcessing: false,
      configStartWitnesspointNames: [],
      configEndWitnesspointNames: [],
      configIntervalTypes: [{ name: 'Outer time measurement', value: 'OuterTime' }, { name: 'Inner time measurement', value: 'InnerTime' }, { name: 'Sum of durations', value: 'SumDuration' }],
      configIntervalType: 'OuterTime',
      configIntervalName: '',
      configStartDate: '',
      configEndDate: '',
      witnesspointListsValid: false,

      // Explorer Dialog
      newAndRenameDialog: false,
      newAndRenameValid: false,
      newAndRenameDialogRenaming: false,
      newAndRenameOriginal: '',
      newAndRenameName: '',
      newAndRenameProcessing: false,
      newAndRenameError: '',

      // Explorer Rules
      rules: {
        required: value => !!value || 'A value is required',
        atLeastOneItem: value => {
          return (value || []).length > 0 || 'At least one witness point needs to be selected';
        },
        minLength: value => {
          return (value || '').length > 2 || 'Interval name must contain at least 3 characters';
        }
      },

      witnessSummaryDetails: {}
    };
  },
  
  /************
   * Computed *
   ************/

  computed: {
    ...mapGetters({
      currentClinic: 'currentClinic',
      allClinics: 'allClinics',
      deviceInfo: 'allDeviceInfo',
      getWitnessDBs: 'witnessDBs',
      isAdminOfCurrentClinic: 'isAdminOfCurrentClinic',
      witnessInstallList: 'witnessInstallList',
      dateFilter: 'dateFilter'
    }),
    
    loading: function () {
      return this.witnesspointNamesLoading || this.histogramDataLoading || this.historicalDataLoading;
    },
    titleFull: function() {
      let title = '';
      if (!this.explorerIsOpen) {
        title = this.titleDescription;
      }
      else {
        title = 'Custom Interval';
      }
      
      return title;
    },
    titleDescription: function () {
      // early out for interval explorer
      if (this.explorerIsOpen) {
        return;
      }

      let title = '';

      if (this.selectedIntervalConfig) {
        // let clinicNames = this.allClinics.reduce((acc, cur) => ({ ...acc, [cur.clinicId]: cur.clinicName }), {});
        // title = 'Avg. Duration of "' + this.selectedIntervalConfig.name + '" Interval - ' + clinicNames[this.witnessSummaryDetails.clinicId];
        title = 'Avg. Duration of "' + this.selectedIntervalConfig.name + '" Interval - ' + generalFunctions.getClinicNameFromClinicGuid(this.selectedClinicId);
      }

      return title;
    },
    summaryURL: function () {
      // console.log('summaryURLNew', this.selectedClinicId, this.selectedDeviceId, this.selectedIntervalId, this.selectedStartDate);
      
      const templateURL = '/clinics/{clinicId}/devices/{deviceId}/intervalsummary/{intervalId}?date={dateValue}';
      let outputURL = '';

      // test for minimum requirements to construct the URL
      if (this.selectedClinicId && this.selectedDeviceId && this.selectedIntervalId && this.selectedStartDate) {
        outputURL = templateURL
          .replace('{clinicId}', this.selectedClinicId || '')
          .replace('{deviceId}', this.selectedDeviceId)
          .replace('{intervalId}', this.selectedIntervalId || '')
          .replace('{dateValue}', this.selectedStartDate || '');

        return outputURL;
      }
      return undefined;
    },
    summaryData: function () {
      if (!this.chartData || !this.chartData.historical) {
        return undefined;
      }

      const output = {};

      return output;
    },
    intervalTypeDescription: function () {
      // console.log('WI-VM: computed - intervalTypeDescription');
      
      let output = '';
      if (!this.explorerIsOpen) {
        if (this.selectedIntervalConfig && 'intervaltype' in this.selectedIntervalConfig) {
          output += this.selectedIntervalConfig.intervaltype;
        }
      } else {
        if (this.configIntervalType) {
          output += this.configIntervalType;
        }
      }
      return output;
    },
    intervalStartGroupDescription: function () {
      // console.log('WI-VM: computed - intervalStartGroupDescription');
      
      let output = '';
      if (!this.explorerIsOpen) {
        if (this.selectedIntervalConfig && 'startwitnesspoints' in this.selectedIntervalConfig && Array.isArray(this.selectedIntervalConfig.startwitnesspoints) && this.selectedIntervalConfig.startwitnesspoints.length > 0) {
          // start group
          this.selectedIntervalConfig.startwitnesspoints.forEach((item) => {
            output += item + ', ';
          }, this);
          output = output.slice(0, -2);
        }
      } else {
        if (this.configStartWitnesspointNames && Array.isArray(this.configStartWitnesspointNames) && this.configStartWitnesspointNames.length > 0) {
          // start group
          this.configStartWitnesspointNames.forEach((item) => {
            output += item + ', ';
          }, this);
          output = output.slice(0, -2);
        }
      }
      return output;
    },
    intervalEndGroupDescription: function () {
      // console.log('WI-VM: computed - intervalEndGroupDescription');
      
      let output = '';
      if (!this.explorerIsOpen) {
        if (this.selectedIntervalConfig && 'endwitnesspoints' in this.selectedIntervalConfig && Array.isArray(this.selectedIntervalConfig.endwitnesspoints) && this.selectedIntervalConfig.endwitnesspoints.length > 0) {
          // start group
          this.selectedIntervalConfig.endwitnesspoints.forEach((item) => {
            output += item + ', ';
          }, this);
          output = output.slice(0, -2);
        }
      } else {
        if (this.configEndWitnesspointNames && Array.isArray(this.configEndWitnesspointNames) && this.configEndWitnesspointNames.length > 0) {
          // start group
          this.configEndWitnesspointNames.forEach((item) => {
            output += item + ', ';
          }, this);
          output = output.slice(0, -2);
        }
      }
      return output;
    },
    modeKey: function () {
      if (this.tabs === 0) {
        return 'count';
      } else if (this.tabs === 1) {
        return 'average_duration';
      } else {
        return 'total_time';
      }
    },
    dateRangeText: function () {
      if (this.datePickerRangeOrdered) {
        return this.datePickerRangeOrdered.join(' ~ ');
      } else if (this.dateRangePickerOutput && this.dateRangePickerOutput.length > 0) {
        return this.dateRangePickerOutput[0] + ' ~ ';
      }
      return undefined;
    },
    datePickerRangeOrdered: function () {
      if (this.datesFormARange) {
        const date1 = new Date(this.dateRangePickerOutput[0]);
        const date2 = new Date(this.dateRangePickerOutput[1]);

        if (date1 < date2) {
          return this.dateRangePickerOutput;
        } else {
          return [date2.toISOString().substr(0, 10), date1.toISOString().substr(0, 10)];
        }
      } else {
        return undefined;
      }
    },
    datesFormARange: function () {
      if (this.dateRangePickerOutput && this.dateRangePickerOutput.length === 2) {
        return true;
      }
      return false;
    },
    jumpPeriodForHistoricalChart: function () {
      return this.dateFilter.jumpPeriod;
    }
  },

  /*********
   * Watch *
   *********/

  watch: {
    configStartWitnesspointNames: async function (newVal, oldVal) {
      // console.log('WI-VM: watch - configStartWitnesspointNames');
      
      if (this.explorerIsOpen && this.configStartWitnesspointNames && this.configStartWitnesspointNames.length > 0 && ((this.configEndWitnesspointNames && Array.isArray(this.configEndWitnesspointNames) && this.configEndWitnesspointNames.length > 0 && this.configIntervalType !== 'SumDuration') || this.configIntervalType === 'SumDuration')) {
        this.reloadInterval();
      } else {
        this.clearInterval();
      }
    },
    configEndWitnesspointNames: async function (newVal, oldVal) {
      // console.log('WI-VM: watch - configEndWitnesspointNames');
      
      if (this.explorerIsOpen && this.configStartWitnesspointNames && this.configStartWitnesspointNames.length > 0 && ((this.configEndWitnesspointNames && Array.isArray(this.configEndWitnesspointNames) && this.configEndWitnesspointNames.length > 0 && this.configIntervalType !== 'SumDuration') || this.configIntervalType === 'SumDuration')) {
        this.reloadInterval();
      } else {
        this.clearInterval();
      }
    },
    configIntervalType: async function (newVal, oldVal) {
      // console.log('WI-VM: watch - configIntervalType');
      
      if (this.explorerIsOpen && this.configStartWitnesspointNames && this.configStartWitnesspointNames.length > 0 && ((this.configEndWitnesspointNames && Array.isArray(this.configEndWitnesspointNames) && this.configEndWitnesspointNames.length > 0 && this.configIntervalType !== 'SumDuration') || this.configIntervalType === 'SumDuration')) {
        this.reloadInterval();
      } else {
        this.clearInterval();
      }
    }
  },
  mounted() {
  },

  /***********
   * Methods *
   ***********/

  methods: {
    formatDT: dateTimeFormatter.deconstructDateTimeSeconds,
    
    //
    // Event Handlers
    //
    selectionChanged: function (clinicId, deviceId, intervalData) {
      // console.log('WI-VM: selectionChanged', clinicId, deviceId, intervalData);
      
      let clinicChanged = false;
      let intervalChanged = false;
      if (this.selectedClinicId != clinicId) {
        // console.log('WI-VM: selectionChanged - Clinic changed:', this.selectedClinicId, clinicId);
        clinicChanged = true;
        // Clinic change => Interval change except that could be null to null
        // intervalChanged = true;  
      }
      if (intervalData) {
        if (this.selectedIntervalId != intervalData.intervalID) {
          // console.log('WI-VM: selectionChanged - Interval changed:', this.selectedIntervalId, intervalData.intervalID);
          intervalChanged = true;
        }
      }
      else 
      {
        if (this.selectedIntervalId) {
          // console.log('WI-VM: selectionChanged - Interval changed:', this.selectedIntervalId, null);
          intervalChanged = true;
        }
      }
      
      // Update Summary URL (New)
      this.selectedClinicId = clinicId;
      this.selectedDeviceId = deviceId;

      if (intervalData) {
        // TIDY: Use config of selected Interval 
        this.configIntervalName = intervalData.name;
        this.configIntervalType = intervalData.intervaltype;
        this.configStartWitnesspointNames = intervalData.startwitnesspoints;
        this.configEndWitnesspointNames = intervalData.endwitnesspoints;
        
        this.selectedIntervalId = intervalData.intervalID;
      }
      else {
        this.configIntervalName = 'To Be Set';
        this.configIntervalType = 'OuterTime';
        this.configStartWitnesspointNames = [];
        this.configEndWitnesspointNames = [];
        
        this.selectedIntervalId = null;
      }  
      
      // Keep copy of Interval config
      this.selectedIntervalConfig = intervalData;
      
      // Load WP names for selected Clinic (Device) once we've logged selected deviceId
      this.retrieveWitnesspointNames();
            
      if (intervalData) {
        this.reloadInterval();
      }
      else {
        this.clearInterval();
      }
      
      // Explorer - open it when we have no Interval
      if (!this.explorerIsOpen && this.selectedIntervalId == null) {
        this.explorerIsOpen = true;
      }
      // Explorer - close it when Clinic or Interval changed if we have an Interval
      else if (this.explorerIsOpen && (clinicChanged || intervalChanged) && this.selectedIntervalId != null) {
        this.explorerIsOpen = false;
      }

      this.updateWitnessSummaryDetails();
    },
    dateRangeChanged: function (startDate, endDate, dateJump, datePeriod) {
      // console.log('WI-VM: dateRangeChanged', startDate, endDate, dateJump, datePeriod);

      this.selectedStartDate = startDate;
      
      // TIDY: And current date filters
      this.configStartDate = startDate;
      this.configEndDate = endDate;
      
      // Map datePeriod to groupBy
      const info = this.updateApiDateData(startDate, dateJump, datePeriod);
      this.historicalStartDate = info.startDate;
      this.historicalGroupBy = info.groupBy;
      
      if (this.selectedClinicId && this.selectedDeviceId) {
        this.reloadInterval();
      }
    },
    //
    // Internal
    //
    updateWitnessSummaryDetails: function () {
      this.witnessSummaryDetails = {
        clinicId: this.selectedClinicId,
        intervalId: this.selectedIntervalId,
        intervalTitle: this.titleFull
      };
    },
    reloadInterval: async function () {
      // console.log('WI-VM: reloadInterval');
      
      // Get the data
      this.witnessIntervalDataLoading = true;
      
      if (!this.explorerIsOpen) {
        this.updateWitnessSummaryDetails();
      }

      this.updateHistorical();
      this.updateHistogram();
      
      this.witnessIntervalDataLoading = false;
      
    },
    updateHistorical: async function () {
      var response = undefined;
      this.historicalDataLoading = true;

      if (!this.explorerIsOpen) {
        response = await apiEndpoints.getWitnessIntervalDataFromId(
          this.selectedClinicId,
          this.selectedIntervalId,
          this.historicalStartDate, 
          this.configEndDate,
          this.historicalGroupBy,
          'intervalsViewReloadIntervalHistorical'
        );
      } else {
        response = await apiEndpoints.getWitnessIntervalDataFromConfig(
          this.selectedClinicId,
          this.configStartWitnesspointNames,
          this.configEndWitnesspointNames,
          this.configIntervalType,
          this.historicalStartDate,
          this.configEndDate,
          this.historicalGroupBy,
          'intervalsViewReloadIntervalHistorical'
        );
      }

      let responseHistorical = undefined;
      if (response.result && response.mostRecentWithCancelKey) {
        responseHistorical = response.data;
      }

      // Prepare the historical data
      const historicalOutput = this.initHistoricalOutput();

      if (responseHistorical && Array.isArray(responseHistorical) && responseHistorical.length > 0) {
        // Construct historical data object

        // Reverse order
        responseHistorical.reverse();
        // Remove the earliest element to show 12 months, 4 quarters etc.
        responseHistorical.shift();
        
        // Pivot the historical data (array of objects -> object containing arrays)
        const historicalPivot = {};
        responseHistorical.forEach(obj => {
          Object.keys(obj).forEach(key => {
            historicalPivot[key] = (historicalPivot[key] || []).concat([obj[key]]);
          });
        });
        
        // Remap to the expected key names
        if (historicalPivot.date) {
          historicalOutput.dates = historicalPivot.date;
        }
        if (historicalPivot.count) {
          historicalOutput.count_with_duration = historicalPivot.count;
        }
        if (historicalPivot.averagetimeseconds) {
          historicalOutput.average_duration = historicalPivot.averagetimeseconds;
        }
      }

      if (historicalOutput?.average_duration?.length) {
        // Construct a version of the original object (for compatibility with the component)
        this.chartData['historical'] = historicalOutput;
        this.historicalDataLoading = false;
      }
    },
    updateHistogram: async function () {
      var response = undefined;
      this.histogramDataLoading = true;

      if (!this.explorerIsOpen) {
        response = await apiEndpoints.getWitnessIntervalDataFromId(
          this.selectedClinicId,
          this.selectedIntervalId,
          this.configStartDate,
          this.configEndDate,
          'duration',
          'intervalsViewReloadIntervalHistogram'
        );
      } else {
        response = await apiEndpoints.getWitnessIntervalDataFromConfig(
          this.selectedClinicId,
          this.configStartWitnesspointNames,
          this.configEndWitnesspointNames,
          this.configIntervalType,
          this.configStartDate,
          this.configEndDate,
          'duration',
          'intervalsViewReloadIntervalHistogram'
        );
      }

      let responseHistogram = undefined;
      if (response.result && response.mostRecentWithCancelKey) {
        responseHistogram = response.data;
      }

      // console.log(responseHistogram);
      const histogramOutput = this.initHistogramOutput();
      
      if (responseHistogram && Array.isArray(responseHistogram) && responseHistogram.length > 0) {
        let totalCount = 0;
        let totalDuration = 0;
        
        responseHistogram.forEach(duration => {
          histogramOutput.binNum.push(duration.durationminutes);
          histogramOutput.binVal.push(duration.durationminutes);
          histogramOutput.count.push(duration.count);
          
          totalCount += duration.count;
          totalDuration += duration.durationminutes * duration.count;
        });
        
        histogramOutput.avg_average_duration = totalDuration / totalCount;
      }
      // console.log(histogramOutput);

      if (histogramOutput && response.result && response.mostRecentWithCancelKey) {
        // Construct a version of the original object (for compatibility with the component)
        this.chartData['histogram'] = histogramOutput;
        this.histogramDataLoading = false;
      }
    },
    clearInterval: async function () {
      // console.log('WI-VM: clearInterval');
      
      const histogramOutput = this.initHistogramOutput();
      const historicalOutput = this.initHistoricalOutput();
      
      if (histogramOutput && historicalOutput) {
        // Construct a version of the original object (for compatibility with the component)
        let actualObject = {};

        actualObject = {
          histogram: histogramOutput,
          historical: historicalOutput
        };
        this.chartData = actualObject;
      } else {
        this.errorMessage = 'No data is available for the current configuration';
        this.showError = true;
      }
    },
    updateApiDateData: function (startDate, dateJumpAmount, dateJumpPeriod) {
      // console.log('updateApiDateData', startDate, dateJumpAmount, dateJumpPeriod);
      
      let apiGroupBy = 'all';

      if (dateJumpAmount == 1) {
        apiGroupBy = dateJumpPeriod;
      } else if (dateJumpAmount > 1) {
        apiGroupBy = dateJumpAmount + 'days';
      }
      
      let apiDateMultiplier = 1;

      if (dateJumpAmount == 1 && dateJumpPeriod == 'day') {
        apiDateMultiplier = 7;
      } else if (dateJumpAmount == 7 || dateJumpPeriod == 'week' || dateJumpAmount == 90 || dateJumpPeriod == 'quarter') {
        apiDateMultiplier = 4;
      } else if (dateJumpAmount == 30 || dateJumpPeriod == 'month') {
        apiDateMultiplier = 12;
      } else if (dateJumpAmount == 365 || dateJumpPeriod == 'year') {
        apiDateMultiplier = 5;
      }
      
      const formattedStartDate = dayjs(startDate, 'YYYY-MM-DD');
      
      let apiStartDate = null;

      if (apiDateMultiplier == 1) {
        apiStartDate = startDate;
      } else {
        apiStartDate = formattedStartDate.subtract(apiDateMultiplier * dateJumpAmount, dateJumpPeriod).format('YYYY-MM-DD');
      }
      this.apiDateMultiplier = apiDateMultiplier;
      
      const info = {
        startDate: apiStartDate,
        groupBy: apiGroupBy,
        multiplier: apiDateMultiplier
      };
      return info;
    },
    newAndRenameSave: async function () {
      if (this.newAndRenameDialogRenaming) {
        // rename with confirmation
        if (!confirm('Are you sure you want to rename the interval configuration "' + this.newAndRenameOriginal + '" to "' + this.newAndRenameName + '"? This will discard any other changes made to the configuration.')) {
          return;
        }
        // set processing state for dialog
        
        this.newAndRenameProcessing = true;

        // construct the new config object from original config - ignores other changes made so far
        const config = generalFunctions.deepCopy(this.selectedIntervalConfig);
        config.name = this.newAndRenameName;
        delete config.intervalID;
        
        await this.saveConfigChanges(config, 'put');
      } else {
        // set processing state for dialog
        this.newAndRenameProcessing = true;

        // save as new
        // construct new config object
        const config = {
          name: this.newAndRenameName,
          startwitnesspoints: this.configStartWitnesspointNames,
          endwitnesspoints: this.configEndWitnesspointNames,
          intervaltype: this.configIntervalType
        };

        await this.saveConfigChanges(config, 'post');
      }
    },
    newAndRenameClose: function () {
      this.newAndRenameDialog = false;
      this.newAndRenameProcessing = false;
      this.newAndRenameError = '';
    },
    clickDelete: function () {
      if (!confirm('Are you sure you want to delete the configuration "' + this.configIntervalName + '"?')) {
        return;
      } else {
        this.deleteConfig();
      }
    },
    deleteConfig: async function () {
      let requestStatus = await apiEndpoints.deleteWitnessIntervalConfig(this.selectedClinicId, this.selectedDeviceId, this.selectedIntervalId);

      if (requestStatus.result) {
        // Tell Filter to reload Intervals - and it will auto-select first Interval
        this.$refs.intervalFilter.reloadIntervals(this.selectedClinicId, this.selectedDeviceId, null);
      }
    },
    clickSaveAsNew: function () {
      this.newAndRenameDialogRenaming = false;
      this.newAndRenameName = '';
      this.newAndRenameOriginal = '';
      this.newAndRenameDialog = true;
    },
    clickSave: function () {
      // rename with confirmation
      if (!confirm('Are you sure you want to save the changes made to interval configuration "' + this.configIntervalName + '"?')) {
        return;
      } else {
        const config = {
          name: this.configIntervalName,
          startwitnesspoints: this.configStartWitnesspointNames,
          endwitnesspoints: this.configEndWitnesspointNames,
          intervaltype: this.configIntervalType
        };
        this.saveConfigChanges(config, 'put');
      }
    },
    saveConfigChanges: async function (config, putOrPost) {
      // console.log('WI-VM: saveConfigChanges');
      
      // set processing state for dialog
      this.newAndRenameProcessing = true;

      let requestStatus = null;
      let returnedConfig = undefined;
      
      if (putOrPost == 'put') {
        // Update
        requestStatus = await apiEndpoints.putWitnessIntervalConfig(this.selectedClinicId, this.selectedDeviceId, this.selectedIntervalId, config);
      } else if (putOrPost == 'post') {
        // Create
        requestStatus = await apiEndpoints.postWitnessIntervalConfig(this.selectedClinicId, this.selectedDeviceId, config);
      }

      // Check for Request error
      if (requestStatus.result) {
        returnedConfig = requestStatus.data;
      }
      
      if (returnedConfig) {
        // Tell Filter to reload Intervals and select the new Interval
        this.$refs.intervalFilter.reloadIntervals(this.selectedClinicId, this.selectedDeviceId, returnedConfig.intervalID);

        // close the dialog
        this.newAndRenameDialog = false;

        // set processing state for dialog
        this.newAndRenameProcessing = false;
      }
      
      if (!requestStatus.result) {
        if (this.newAndRenameDialog) {
          this.newAndRenameProcessing = false;
          this.newAndRenameError = requestStatus.error.userMessage;
        }
      }

    },
    clickRename: function () {
      this.newAndRenameDialogRenaming = true;
      this.newAndRenameName = this.configIntervalName;
      this.newAndRenameOriginal = this.configIntervalName;
      this.newAndRenameDialog = true;
    },
    clickExplore: function () {
      this.explorerIsOpen = true;
    },
    closeExplorer: function () {
      this.explorerIsOpen = false;
    },
    filterToProvidedDateRange: function (payload) {
      console.log('!!! WI-VM: filterToProvidedDateRange');
    },
    retrieveWitnesspointNames: async function () {
      this.witnesspointNamesLoading = true;
      
      let response = await apiEndpoints.getWitnessPointNames(this.selectedClinicId, this.selectedDeviceId);
      
      if (response.result) {
        let wpNames = response.data;
        
        if (wpNames) {
          wpNames = wpNames.filter((name) => {
            if (name === '' || name == null || name === undefined || !name.replace(/\s/g, '').length) {
              return false;
            } else {
              return true;
            }
          });
        }
  
        this.witnesspointNames = generalFunctions.deepCopy(wpNames);
      }
  
      this.witnesspointNamesLoading = false;
    },
    initHistogramOutput: function () {
      const histogramOutput = {};
      histogramOutput.binSize = 1;
      histogramOutput.binNum = [];
      histogramOutput.binVal = [];
      histogramOutput.count = [];
      histogramOutput.average_duration = [];
      histogramOutput.avg_average_duration = 0;

      return histogramOutput;
    },
    initHistoricalOutput: function () {
      const historicalOutput = {};
      historicalOutput.dates = [];
      historicalOutput.count_with_duration = [];
      historicalOutput.average_duration = [];
      
      return historicalOutput;
    }
    
  }
};
